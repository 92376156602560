import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import logo from "../images/logo.png" // Tell Webpack this JS file uses this image

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <nav>
          <ul>
            <li className="home"><Link to="/"><img src={logo} className="logo" height="35" alt="Logo"/></Link></li>
            <li className="one"><Link to="/">Home</Link></li>
            <li className="two"><Link to="/#About">About</Link></li>
            <li className="three"><Link to="/#Portfolio">Portfolio</Link></li>
            <li className="four"><Link to="/#contact">Contact</Link></li>
          </ul>
        </nav>
        <div id="success" className="bgimg-1">
          <div className="top">
            <h1><span>S</span>uccess</h1>
            <p>Thanks for the message, I will respond as soon as I am able.</p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
